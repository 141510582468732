import { Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";

export default function TropicsDetail() {

    return (
        <>
            <Typography paddingTop={4} paddingBottom={2} variant="h5">
                <i className="fa-sharp-duotone fa-solid fa-hurricane" />  Current Tropical Outlook <i className="fa-sharp-duotone fa-solid fa-hurricane" />
            </Typography>
            <br />
            <Container>
                <Grid container spacing={4} paddingBottom={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card elevation={3}>
                            <CardHeader title="Atlantic Ocean" color="textSecondary" />

                            <CardContent align="left">

                                <>
                                    <Typography variant="body5"> Tropical cyclone formation is not expected during the next 7 days. <br /><br /> </Typography>
                                </>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card elevation={3}>
                            <CardHeader title="Pacific Ocean" color="textSecondary" />

                            <CardContent align="left">

                                <>
                                    <Typography variant="body5"> Tropical cyclone formation is not expected during the next 7 days. <br /><br /> </Typography>
                                </>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>)

}
